



































































































































































































































































































































































































import Vue from 'vue';
import { Context } from '@nuxt/types';
import moment from 'moment';
import { ResponseCode } from '~/util/constant';
import { gaodeKey } from '~/util/common';
// @ts-ignore
import vueQr from 'vue-qr';
import _ from 'lodash';
import { formRules } from './validate.js';
import payVisaMixin from './pay-visa-mixin';
import { serviceObj, serviceTicket } from '~/pages/fair/service';
import { ageRangeOption, userTypeOption, defaultVisaObj } from './helper';

export default Vue.extend<any, any, any>({
  layout: 'defaultVisa',
  components: { vueQr },
  mixins: [payVisaMixin],
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   if (path === '/information' || path === '/information/') {
  //     return true;
  //   }
  //   const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   return _validate;
  // },
  async asyncData(ctx) {
    return {};
  },
  data(): Record<string, any> {
    return {
      ageRangeOption,
      userTypeOption,
      areaProps: {
        label: 'name',
        value: 'name',
        children: 'districts',
      },

      areaData: [],
      mailType: 1,
      pickerOptions: {
        disabledDate: (time: any) => {
          // 禁用今天之前的日期选择
          // 禁用未来10天内的日期 10 * 24 * 60 * 60 * 1000
          // return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
          // 材料快递时间
          let postTime = 3;
          // 签证办理时长(天)
          let days = Number(this.setMealInfo.processTime) || 10;
          return (
            time.getTime() <
            new Date().getTime() + (days + postTime) * 24 * 60 * 60 * 1000
          );
        },
      },
      ruleForm: {
        planTime: '',
        materialCloseTime: '',
        buyerMsg: '',
        visaBuyers: {
          ageRange: '',
          firstName: '',
          idEffectiveTime: '',
          idNo: '',
          lastName: '',
          name: '',
          passportEffectiveTime: '',
          passportNo: '',
          userType: '',
        },
        contact: '',
        phone: '',
        area: null,
        visaReciveAdress: {
          city: '',
          detailAddress: '',
          district: '',
          location: '',
          name: '',
          phone: '',
          province: '',
        },
      },
      ruleFormTest: {
        planTime: '',
        materialCloseTime: '',
        buyerMsg: '测试',
        visaBuyers: {
          ageRange: 1,
          firstName: 'SAN',
          idEffectiveTime: '2027-07-22 00:00:00',
          idNo: '330102199904021811',
          lastName: 'ZHANG',
          name: '张三',
          passportEffectiveTime: '2032-07-16 00:00:00',
          passportNo: '123456',
          userType: 2,
        },
        contact: '李四',
        phone: '17610770968',
        area: ['浙江省', '杭州市', '余杭区'],
        visaReciveAdress: {
          city: '杭州市',
          detailAddress: '你好街道112号',
          district: '余杭区',
          location: '',
          name: '张三',
          phone: '17610770968',
          province: '浙江省',
        },
      },
      rules: {
        ...formRules,
      },
      remark: '',
      payType: 1,
      activeStep: 'order',
      aliform: null,
      showQrcode: false,
      code: {} as any,
      loadingPay: false,
      visaInfo: JSON.parse(JSON.stringify(defaultVisaObj)),
      setMealInfo: JSON.parse(JSON.stringify(defaultVisaObj.visaPackages[0])),
      num: 1,
      unitPrice: 0,
      totalPrice: 0,
    };
  },
  created() {
    // (document.getElementsByTagName('header') as any)[0].style.display = 'none';

    this.getVisaInfo();
    this.handleGetDistrict();
  },
  mounted() {
    // 自动填写测试数据
    try {
      let routeQuery = this.$route.query;
      if (routeQuery.hasOwnProperty('debug')) {
        for (let i in this.ruleForm) {
          this.ruleForm[i] = this.ruleFormTest[i];
        }
      }
    } catch (err) {}
  },
  methods: {
    /**
     * 获取签证信息
     * @returns {any}
     */
    async getVisaInfo() {
      const { id, setMealId, num = 1, unitPrice = 0 } = this.$route.query;
      const result: any[] = await Promise.all([
        this.$axios.post('/api/visaInfoApi/get', { id: id }),
      ]);
      const [visaResult] = result;
      // let visaInfo = {};
      // let setMealInfo: any = {};
      // let totalPrice = '';

      if (visaResult.code === '1') {
        this.visaInfo = visaResult.data;
        this.setMealInfo = visaResult.data.visaPackages.find(
          (i: any) => i.id == setMealId
        );
        this.totalPrice = Math.multiply(
          this.setMealInfo.price,
          Number(num)
        ).toFixed(2); // setMealInfo.price * Number(num);
      }
    },
    departureDateChange(e: any) {
      let days = Number(this.setMealInfo.processTime) || 10;
      if (e) {
        // 截止收材料日期 = 预计触发日期 - 套餐的办理时长(天数)
        this.ruleForm.materialCloseTime = moment(e)
          .subtract(days + 1, 'days')
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.ruleForm.materialCloseTime = '';
      }
    },
    lastNameChange(e: string, str: string) {
      this.ruleForm.visaBuyers[str] = e.toLocaleUpperCase();
    },
    // 高德获取省市区三级数据
    async handleGetDistrict() {
      let areaRes: any = await this.$axios.get(
        `/ossApi/InnerChainInfo/area.json`
      ); // 获取省市区数据

      this.areaData = areaRes;
      return false;
      const res: any = await this.$axios.get(
        `/v3/config/district?key=${gaodeKey}&subdistrict=3`
      );
      if (res.status === ResponseCode.SUCCESS) {
        const area = _.get(res, 'districts[0].districts', []);
        area.forEach((province: any) => {
          province.districts.forEach((city: any) => {
            city.districts.forEach((district: any) => {
              delete district.districts;
            });
          });
        });
        this.areaData = area;
      }
    },
    areaChange(e: any) {
      this.ruleForm.visaReciveAdress.province = e ? e[0] : '';
      this.ruleForm.visaReciveAdress.city = e ? e[1] : '';
      this.ruleForm.visaReciveAdress.district = e ? e[2] : '';
    },
    handleClick(tab: any) {
      // let path = `/information-${tab.name}-${this.industryId}-1/`;
      // if (tab.name === '0' && this.industryId === '0') {
      //   path = '/information/';
      // }
      // this.$router.push({
      //   path,
      // });
    },
    handleChange() {
      // console.log(
      //   295,
      //   '计算总金额',
      //   Number(this.setMealInfo.price),
      //   Number(this.num)
      // );
      // console.log(
      //   615,
      //   Math.multiply(Number(this.setMealInfo.price), Number(this.num))
      // );
      this.totalPrice = Math.multiply(
        Number(this.setMealInfo.price),
        Number(this.num)
      ).toFixed(2);
    },
    submitForm(formName: any) {
      this.$refs[formName].validate((valid: any) => {
        if (valid) {
          this.activeStep = 'pay';
          this.$router.replace({
            path: '/visa/submitOrder',
            query: {
              ...this.$route.query,
              step: 'pay',
            },
          });
        } else {
          return false;
        }
      });
    },
    back() {
      this.activeStep = 'order';
    },
    togglePayment(type: number) {
      this.payType = type;
      // if (type === 'alipay') {
      //   this.$data.showBtn = true;
      // }
    },
    async toPay_123() {
      let { id, setMealId } = this.$route.query;

      let result: any = await this.$axios.post('/api/visaOrderApi/add', {
        visaPackageId: setMealId,
        num: this.num,
        payType: this.payType,
        contact: this.contact,
        phone: this.phone,
        buyerMsg: this.buyerMsg,
        visaInfoMaterials: this.visaInfo.visaInfoMaterials,
      });

      // PC支付宝
      if (this.payType == 1) {
        this.aliform = result.data.body;
        this.$nextTick(() => {
          console.dir(195, document.forms[0]);
          document.forms[0].submit(); //重点--这个才是跳转页面的核心,获取第一个表单并提交
        });
      }
      // PC微信
      if (this.payType == 2) {
        if (result.code === ResponseCode.SUCCESS) {
          this.showQrcode = true;
          this.code = result.data;
        }
      }
    },
  },
  head() {
    const { pageNum, visaInfo } = this.$data;
    return {
      title: `展会资讯_新闻_攻略_报道${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网是国内专业国际展会一站式服务平台,提供最新最全的${new Date().getFullYear()}年国际展会咨询,新闻报道,出行攻略,搭建资讯,全球展会信息平台,国外展会信息,免费咨询。`,
        },
      ],
    };
  },
});
