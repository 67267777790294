


































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';

export default Vue.extend({
  name: 'VisaMaterial',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {};
  },
  computed: {
    materialList: {
      get() {
        let result: any = this.list;
        result.map((item: any) => {
          item['exampleList'] = item.example ? [item.example] : [];
        });
        return result;
      },
    },
  },
  methods: {},
});
