





















































































































































































































































































































































































































































































































import Vue from 'vue';
import _ from 'lodash';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import VisaMaterial from './component/VisaMaterial.vue';
import { visaTypeOption, bizTypeOption } from './helper';
import { serviceList, randomServiceList } from './service';

export default Vue.extend<any, any, any>({
  components: { VisaMaterial },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   if (path === '/information' || path === '/information/') {
  //     return true;
  //   }
  //   const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   return _validate;
  // },
  async asyncData(ctx) {
    let { id } = ctx.params;

    const result: any[] = await Promise.all([
      ctx.$axios.post('/api/visaInfoApi/get', { id: id }),
      ctx.$axios.post('/api/visaInfoApi/allPlace'),
      ctx.$axios.post('/api/visaInfoApi/visaPlace'),
    ]);
    const [visaResult, areaResult, visaPlaceResult] = result;
    let countryList: any = [];
    let areaObject: any = {};
    areaResult.data.map((item: any) => {
      countryList.push(...item.areaList);
    });
    countryList.map((item: any) => {
      areaObject[item.id] = item.area;
    });

    // 送签地
    let visaPlaceObject: any = {};
    visaPlaceResult.data.map((item: any) => {
      Object.keys(item).forEach((i) => {
        // visaPlaceList.push({ id: i, label: item[i] });
        visaPlaceObject[i] = item[i];
      });
    });

    let info = {} as any;
    let picList = [];
    let imageActive = {
      image: '',
      index: 0,
    };
    let activeMaterialList = [];

    if (visaResult.code === '1') {
      info = visaResult.data;
      picList = visaResult.data.pic ? visaResult.data.pic.split(',') : [];
      imageActive.image = picList ? picList[0] : '';
      activeMaterialList = visaResult.data.visaPackages
        ? visaResult.data.visaPackages[0].visaInfoMaterials
        : [];

      // 处理送签地
      let visaPlaceIdList = visaResult.data.visaPlace.split(',');
      info['visaPlaceList'] = [];
      visaPlaceIdList.map((item: any) => {
        info['visaPlaceList'].push(visaPlaceObject[item]);
      });
    }

    return {
      areaObject,
      info,
      picList,
      imageActive,
      activeMaterialList,
      visaPlaceObject,
      // pageNum,
      // pageSize,
    };
  },
  data() {
    return {
      randomServiceList,
      visaTypeOption,
      bizTypeOption,
      info: {
        id: 0,
        name: '',
        type: '',
        advise: '',
        areaId: '',
        checkoutRate: '',
        content: '',
        durationStay: '',
        entries: '',
        processTime: '',
        validityTime: '',
        acceptanceScope: '',
        changeRefundRules: '',
        orderingInformation: '',
        saleNum: 0,
        visaPackages: [
          {
            id: 0,
            name: '',
            originalPrice: '',
            price: '',
            visaInfoMaterials: [
              {
                name: '',
                tip: '',
                example: '',
              },
            ],
            key: Date.now(),
          },
        ],
        visaPlace: '',
      },
      minOriginalPrice: 0,
      maxOriginalPrice: 0,
      minPrice: 0,
      maxPrice: 0,
      setMealOriginalPrice: 0,
      setMealPrice: 0,
      //
      setMealId: 0, // 选中的套餐
      num: 1,
      isFixed: false,
      activeMenu: 0,
      contentSetMealId: 0,
      activeMaterialList: [], // 当前展示的签证材料表
    };
  },
  created() {},
  mounted() {
    let firstSetMealId = _.get(this.info, 'visaPackages[0].id', 0);

    this.contentSetMealId = firstSetMealId;
    this.handlePrice();
    window.addEventListener(
      'scroll',
      this.throttle(this.handleScroll, 200),
      false
    );
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toBuy() {
      window.scroll(0, 0);
    },
    selectStyle(item: string, index: number) {
      // if (hoverIndex.value == index) return
      this.imageActive.index = index;
      this.imageActive.image = item;
    },
    outStyle(item: string, index: number) {
      // this.imageActive.index = index
      // this.imageActive.image = item
    },
    /**
     * 获取套餐内最低价格,最高价格
     * @returns {any}
     */
    handlePrice() {
      let originalPriceList: any = [];
      let priceList: any = [];
      this.info.visaPackages.map((item: any) => {
        if (item.originalPrice) originalPriceList.push(item.originalPrice);
        priceList.push(item.price);
      });

      let newOriginalList = originalPriceList.sort(
        (a: number, b: number) => a - b
      );
      this.minOriginalPrice = newOriginalList[0];
      this.maxOriginalPrice = newOriginalList[newOriginalList.length - 1];
      if (this.minOriginalPrice == this.maxOriginalPrice)
        this.maxOriginalPrice = '';

      let newList = priceList.sort((a: number, b: number) => a - b);
      this.minPrice = newList[0];
      this.maxPrice = newList[newList.length - 1];
      if (this.minPrice == this.maxPrice) this.maxPrice = '';
    },
    handleScroll() {
      let e = document.getElementById('description-content');
      let scroll_top: number = document.documentElement.scrollTop;
      let offset_top: any = e?.offsetTop;
      if (offset_top - scroll_top < 0) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
      //
      let setMeal = document.getElementById('m-set-meal');
      let range = document.getElementById('m-range');
      let content = document.getElementById('m-content');
      let reserve = document.getElementById('m-reserve');
      let refund = document.getElementById('m-refund');
      let setMealTop: number = setMeal?.offsetTop as number;
      if (setMealTop - scroll_top < 0) {
        this.activeMenu = 1;
      }
      let rangeTop: number = range?.offsetTop as number;
      if (rangeTop - scroll_top < 0) {
        this.activeMenu = 2;
      }
      let contentTop: number = content?.offsetTop as number;
      if (contentTop - scroll_top < 0) {
        this.activeMenu = 3;
      }
      let reserveTop: number = reserve?.offsetTop as number;
      if (reserveTop - scroll_top < 0) {
        this.activeMenu = 4;
      }
      let refundTop: number = refund?.offsetTop as number;
      if (refundTop - scroll_top < 0) {
        this.activeMenu = 5;
      }
    },
    throttle(func: any, wait: number) {
      let prev = Date.now();
      return function (this: Window) {
        let now = Date.now();
        let arg = arguments;
        if (now - prev >= wait) {
          func.apply(this, arg);
          prev = Date.now();
        }
      };
    },
    handleSetMeal(item: any) {
      this.setMealId = item.id;
      this.setMealOriginalPrice = item.originalPrice;
      this.setMealPrice = item.price;
      this.minOriginalPrice = '';
      this.maxOriginalPrice = '';
    },
    handleChange(value: number) {
      // console.log(value);
    },

    handleAreaMore() {},
    async handleBuy() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );

        this.$login.show();
        return;
      }

      if (!this.setMealId) {
        this.$message.error('请选择套餐');
        return false;
      }
      this.$router.push({
        path: '/visa/submitOrder',
        query: {
          id: this.$route.params.id,
          setMealId: this.setMealId,
          // num: this.num,
          unitPrice: this.setMealPrice,
        } as any,
      });
    },
    handleMenu(index: number, e: string) {
      this.activeMenu = index;
      // 跳转到指定位置并且平滑滚动
      // this.$el
      //   .querySelector('#' + e)
      //   .scrollIntoView({ block: 'start', behavior: 'smooth' });
      (document.getElementById(e) as any).scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    handleContentSetMeal(item: any) {
      this.contentSetMealId = item.id;
      this.activeMaterialList = item.visaInfoMaterials;
    },
  },
  head() {
    const { pageNum, info, areaObject } = this.$data;
    let visaType = info.type;
    let visaBizType = info.bizType;
    let bizType = `${areaObject[info.areaId]}${
      info.bizType == 1 ? '签证' : ''
    }${(bizTypeOption as any)[visaBizType]}`;
    let titleStr = `${areaObject[info.areaId]}${
      (visaTypeOption as any)[visaType]
    }_${bizType}`;
    return {
      title: `${titleStr}${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-办理-材料-价格-时间-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `${info.name}-聚展`,
        },
      ],
    };
  },
});
